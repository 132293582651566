<template>
    <div>
        <h1> {{ $t('see-what-storm-can') }}</h1>
        <b-btn v-if="type == 'signup'" size="lg" to="/app/signup" class="mt-3 huge-button" variant="primary">{{$t('sign-up-free')}}</b-btn>
        <b-btn v-if="type == 'requestdemo'" size="lg" to="/contact-sales" class="mt-3 huge-button" variant="dark">{{$t('request-a-demo')}}</b-btn>
    </div>
</template>

<script>
export default {
    name: 'FrontendStormBestForYou',
    props: {
        type: {
            type: String,
            default: "signup"
        },
    },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>